<template>
  <ion-page><cyan-page-wrapper
    :title="title"
    nav1="PARTIDO"
    :nav2="'CONSEJO ' + tipoConsejo"
    :ambito="ambito"
    :backRoute="backRoute"
  >
    <div class="mobileShow">
      <div class="banner nivel1">CONSEJO {{ tipoConsejo }}</div>
      <div class="banner nivel2" v-if="!esNacional">
        {{ ambito }}
      </div>
    </div>
    <div color="banner nodata" v-if="!datos.ok">
      {{ datos.reason }}
    </div>
    <div class="banner nodata" v-if="datos.ok && !datos.tieneCargos">
      No hay datos para los cargos de este consejo.
    </div>

    <ion-list v-if="datos.ok && datos.tieneCargos" lines="none">
      <party-entry v-for="v in datos.organicos" :key="v.id" :element="v" />
      <ion-item v-if="datos.coordinaciones.length">
        <div class="banner">COORDINACIONES OPERATIVAS</div>
      </ion-item>
      <party-entry v-for="v in datos.coordinaciones" :key="v.id" :element="v" />
    </ion-list>
  </cyan-page-wrapper></ion-page>
</template>

<script lang="ts">
import CyanPageWrapper, {
  store,
  defineComponent,
} from "@/components/CyanPageWrapper.vue";
import PartyEntry from "@/components/PartyEntry.vue";
import cyanRegions from "@/modules/cyanRegions";

import { IonList, IonItem,IonPage } from "@ionic/vue";

export default defineComponent({
  name: "PartyList",
  components: {
    IonItem,
    IonList,
    IonPage,
    CyanPageWrapper,
    PartyEntry,
  },
  data() {
    return {
    };
  },
  computed: {
    seed(): any {
          return (this as any).$route.params.seedPL || 'SV'
    },
    datos() {
      const s = (this as any).seed;
      if (!s || typeof store.state.partyLists[s] === "undefined")
        return { ok: false, reason: "" };
      return store.state.partyLists[s];
    },

    tipoConsejo() {
      const s = (this as any).seed;
      if (s == "") return "";
      if (s == "SV") return "NACIONAL";
      if (s.length <= 4) return "DEPARTAMENTAL";
      return "MUNICIPAL";
    },

    esNacional() {
      const s = (this as any).seed;
      return s.length <= 2;
    },

    backRoute() {
      const s = (this as any).seed;
      const u = store.state.userData.nivel || "";

      if (s.length == 6) {
        // Municipio
        if (u.length != 6) return "/pickMpio/" + s.substr(0, 4);
      }

      if (s.length == 4) {
        if (u.length <= 2) return "/pickDepto";
      }

      return "/partyLevels";
    },

    title() {
      const _t = this as any;

      if (_t.seed == "SV") return "CONSEJO NACIONAL";
      return "CONSEJO " + _t.tipoConsejo + " " + _t.ambito;
    },

    ambito() {
      const s = (this as any).seed;
      if (s.length <= 2) return "";
      if (s.length <= 4) return cyanRegions.departamentos[s].nombre;

      const m = cyanRegions.municipios[s];

      if (m.nombrePadre != "" && m.nombrePadre != m.nombre)
        return m.nombre + ", " + m.nombrePadre;
      else return m.nombre;
    },
  },
  methods: {},
});
</script>
<style scoped>
ion-list,
ion-item {
  background: transparent;
  --background: transparent;
}
</style>